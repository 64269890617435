import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./thankyou.css";
class Thankyou extends Component {
    state = {  } 
    render() { 
        return (
            <div></div>
        );
    }
}
 
export default Thankyou;